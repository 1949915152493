<!--  -->
<template>
  <div class='lianxi round bj-col-white pad' v-loading="loading" :element-loading-text="loadingText">
    <h3>随堂练习</h3>
    <div class="list">
      <div v-for="(item, index) in list" :key="index">
        <div class="msg">
          <h4>{{index+1}}、【{{item.type}}题】</h4>
        </div>
        <p>{{item.mingcheng}}</p>
        <div class="xuanze">
          <div v-for="(it, idx) in item.answer_arr" :key="idx" @click="onChange(index, idx)">
            <div :class="{'xuanzhong': it.checked}">{{it.label}}</div>
            {{it.title}}
          </div>
        </div>
        <div class="true">
          <el-button size="mini" :type="item.status ? 'success' : 'danger'" @click="item.show = true" v-if="!item.show">显示答案</el-button>
          <el-button size="mini" :type="item.status ? 'success' : 'danger'" @click="item.show = false" v-else>隐藏答案</el-button>
          <span v-if="item.show">正确答案：{{item.true_answer}}</span>
        </div>
      </div>
    </div>
    <div class="btn">
      <el-button round style="width: 120px" size="mini" type="success" @click="submitRoll()">完成练习</el-button>
    </div>

    <div class="popup" v-if="show" @click.self="show = false">
      <div>
        <template v-if="score >= 60">
          <img src="../assets/img/success.png" alt="" class="pass">
          <div class="hint pass">恭喜您！考试合格。</div>
        </template>
        <template v-else>
          <img src="../assets/img/error.png" alt="" class="fail">
          <div class="hint fail">很遗憾！考试未通过。</div>
        </template>
        <el-button style="width: 120px" plain size="medium" @click.stop="handleQuit">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../api/study'

export default {
  components: {
    
  },
  data() {
    return {
      id: '',
      loading: false,
      loadingText: '加载中',
      list: [],
      score: 60,
      show: false
    };
  },
  computed: {},
  filters: {
    
  },
  watch: {},
  created() {
    this.id = this.$route.query.id
    this.getLianxi()
  },
  mounted() {
    
  },
  beforeDestroy () {
    
  },
  //方法集合
  methods: {
    // 获取题目信息
    getLianxi () {
      this.loading = true
      Api.getLianxi({
        keshi_id: this.id
      }).then(res => {
        if (res.data.code == 1) {
          this.loading = false
          let list = res.data.data
          list.forEach((item) => {
            item.show = false
            item.status = true
            item.answer = ''
            item.answer_arr = [
              {
                label: 'A',
                checked: false,
                title: item.answer_a
              },
              {
                label: "B",
                checked: false,
                title: item.answer_b
              },
              {
                label: 'C',
                checked: false,
                title: item.answer_c
              },
              {
                label: 'D',
                checked: false,
                title: item.answer_d
              }
            ]
            item.answer_arr = item.answer_arr.splice(0, Number(item.answer_num))
            if (item.qst_type == 1) {
              item.type = '单选'
            } else if (item.qst_type == 2) {
              item.type = '多选'
            } else {
              item.type = '判断'
              item.answer_arr = [item.answer_arr[0], item.answer_arr[1]]
            }
          });
          this.list = list
        } else {
          this.$messgae.error("练习题获取失败")
        }
      })
    },
    // 选择答案
    onChange (index, idx) {
      let info = this.list[index]
      info.answer_arr[idx].checked = !info.answer_arr[idx].checked
      if (info.type !== '多选') {
        for (let i in info.answer_arr) {
          if (Number(i) != idx) {
            info.answer_arr[i].checked = false
          }
        }
      }
      this.list[index].answer = ''
      info.answer_arr.forEach(item => {
        if (item.checked) {
          this.list[index].answer+=item.label
        }
      })
      if (this.list[index].answer == this.list[index].true_answer) {
        this.list[index].status = true
      } else {
        this.list[index].status = false
      }
      this.list[index] = info
    },
    // 交卷
    submitRoll () {
      let num = 0
      this.list.forEach(item => {
        if (item.answer == item.true_answer) {
          num++
        }
      })
      this.score = parseInt(100/this.list.length*num)
      // this.show = true
      this.$router.go(-1)
    },
    handleQuit () {
      this.show = false
      this.$router.go(-1)
    }
  },
}
</script>

<style lang='less' scoped>
.lianxi {
  position: relative;
  > div {
    width: 90%;
  }
  .list {
    margin-top: 20px;
    max-height: 600px;
    overflow-y: auto;
    .msg {
      margin: 40px 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4, div {
        color: @default;
      }
    }
    p {
      width: 90%;
      word-break: break-all;
      font-weight: 400;
      line-height: 25px;
    }
    .xuanze {
      > div {
        display: flex;
        align-items: center;
        margin-top: 20px;
        cursor: pointer;
        width: fit-content;
        div {
          border: 1px solid #d8d8d8;
          width: 25px;
          height: 25px;
          line-height: 25px;
          margin-right: 10px;
          text-align: center;
          border-radius: 50%;
          background-color: #fff;
        }
        .xuanzhong {
          border-color: @default;
          color: #fff;
          background-color: @default;
        }
      }
    }
    .true {
      margin-top: 15px;
      display: flex;
      align-items: center;
      > span {
        margin-left: 20px;
      }
    }
  }
  .btn {
    margin: 80px 0 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .popup {
    width: 100%;
    height: 100%;
    background-color: rgba(000, 000, 000, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 999;
    > div {
      width: 500px;
      margin-top: 5%;
      background-color: #ffffff;
      border-radius: 5px;
      height: fit-content;
      padding: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 100px;
      }
      .hint {
        margin: 20px 0;
      }
      .grade {
        margin-bottom: 20px;
        span {
          font-size: 18px;
        }
      }
    }
    .fail {
      color: red;
    }
    .pass {
      color: #00a300;
    }
  }
}
</style>